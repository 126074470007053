import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion, Alert, Avatar, Button, Checkbox, ChevronEndIcon, ChevronStartIcon, Dropdown, ExclamationTriangleIcon, Flex, FlexItem, Header, InfoIcon, Input, Loader, ParticipantAddIcon, PresenceAvailableIcon, RadioGroup, SaveIcon, SearchIcon, Segment, Table, Text, Tooltip, UndoIcon, } from "@fluentui/react-northstar";
import { translations } from "../../translations";
import { useLogic } from "./Permissions.logic";
import "./Permissions.styles.scss";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { Modal } from "../../interfaces/Modal";
import { CancelDialog } from "../../components/dialogs/Cancel/CancelDialog";
import { Role } from "../../interfaces/Permissions";
import { useState } from "react";
import { ThemeClass } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer.interfaces";
import { ManageRoleDialog } from "../../components/dialogs/ManageRole/ManageRoleDialog";
import { configPerms } from "../../const/configPerms";
import { useTranslate } from "../../hook/useTranslate";
export const Permissions = () => {
    const logic = useLogic();
    const { isOnMobile, themeClass } = useMsTeamsSelector("isOnMobile", "themeClass");
    const [isReturnToApp, setIsReturnToApp] = useState(false);
    const [isPreviousPage, setIsPreviousPage] = useState(false);
    const [isNextPage, setIsNextPage] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [userIndex, setUserIndex] = useState(0);
    const [selectedRole, setSelectedRole] = useState();
    const pages = Array.from({ length: logic.totalPage }, (_, i) => i + 1);
    const appConfigPerms = configPerms[logic.from];
    const t = useTranslate(translations);
    const renderCustomizeTable = () => {
        if (!logic.state.globalPermissions.isCustom) {
            return (_jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%", marginBottom: "16px" }, warning: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { outline: true }), _jsx(Text, { content: t("SelectCustomize") })] }) }) }));
        }
        return (_jsx(Flex, { column: true, fill: true, id: "manage-table-perm-pos", styles: { margin: "0px" }, gap: "gap.medium", children: _jsxs(Flex, { column: true, styles: { width: "100%" }, gap: "gap.large", children: [_jsx(Flex, { gap: "gap.medium", id: "margin-bottom", styles: { flexDirection: isOnMobile ? "column" : "row" }, children: _jsxs(Flex, { gap: "gap.medium", id: "no-margin", styles: { justifyContent: isOnMobile ? "normal" : "end", width: "100%", flexDirection: "row", marginTop: isOnMobile ? "20px" : "none" }, className: "permissions-btns-container", children: [_jsx(FlexItem, { children: _jsx(Button, { primary: true, content: isOnMobile ? "" : t("Permissions:AddMemberButton"), onClick: logic.toggleAddMemberDialog, style: {
                                            maxWidth: "fit-content",
                                            minWidth: !isOnMobile ? "fit-content" : "fit-content",
                                        }, icon: _jsx(ParticipantAddIcon, { outline: true }), disabled: logic.isCurrentUserNotCreator() }) }), _jsx(FlexItem, { children: _jsx(Tooltip, { content: !logic.compareState && t("SaveBeforeSearch"), children: _jsx(Flex, { children: _jsx(Input, { inverted: true, icon: _jsx(SearchIcon, {}), placeholder: t("Permissions:Search"), style: {
                                                    maxWidth: `${t("Permissions:Search").length + 10}ch`,
                                                    borderRadius: "5px",
                                                    pointerEvents: !logic.compareState ? "none" : "auto",
                                                }, iconPosition: "end", fluid: true, onChange: logic.compareState
                                                    ? (_, data) => {
                                                        setIsSearch(false), logic.searchOnChange(data);
                                                    }
                                                    : () => (setIsSearch(true), logic.toggleCancelDialog()), disabled: logic.isCurrentUserNotCreator() || !logic.compareState }) }) }) })] }) }), !logic.loading ? (_jsxs(_Fragment, { children: [_jsxs(Flex, { column: true, id: "margin-bottom", styles: { overflow: "scroll !important" }, gap: "gap.medium", children: [logic.isOpen === Modal.PERMISSIONS_ROLE_CHANGE && (_jsx(ManageRoleDialog, { handleRoleChange: logic.handleRoleChange, index: userIndex, selectedRole: selectedRole })), _jsxs(Table, { styles: { overflowY: "scroll !important", minWidth: logic.calculateMinWitdthTable }, id: "usersPermissionsTable", children: [renderHeaderTable(), renderBodyTableRow()] })] }), _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(Button, { size: "small", iconOnly: true, secondary: true, disabled: logic.currentPage === 1, icon: _jsx(ChevronStartIcon, {}), onClick: logic.compareState
                                            ? () => {
                                                setIsPreviousPage(false), logic.handlePageChange(logic.currentPage - 1);
                                            }
                                            : () => {
                                                setIsPreviousPage(true), logic.toggleCancelDialog();
                                            } }), pages.map((page) => page === logic.currentPage || page === logic.currentPage + 1 || page === logic.currentPage - 1 ? (_jsx(Button, { onClick: logic.compareState
                                            ? () => {
                                                setIsPreviousPage(false), setIsNextPage(false), logic.handlePageChange(page);
                                            }
                                            : () => {
                                                logic.currentPage > page ? setIsPreviousPage(true) : logic.currentPage < page ? setIsNextPage(true) : null,
                                                    logic.toggleCancelDialog();
                                            }, content: `${page}`, primary: logic.currentPage === page ? true : false, secondary: logic.currentPage === page ? false : true, size: "small", iconOnly: true }, page)) : null), _jsx(Button, { size: "small", iconOnly: true, secondary: true, disabled: logic.currentPage === logic.totalPage, icon: _jsx(ChevronEndIcon, {}), onClick: logic.compareState
                                            ? () => {
                                                setIsNextPage(false), logic.handlePageChange(logic.currentPage + 1);
                                            }
                                            : () => {
                                                setIsNextPage(true), logic.toggleCancelDialog();
                                            } })] })] })) : (_jsx(Flex, { column: true, fill: true, styles: { height: "400px", justifyContent: "center", alignItems: "center", alignContent: "center" }, children: _jsx(Loader, {}) }))] }) }));
    };
    const renderSettingTableRow = () => {
        return (_jsx(_Fragment, { children: _jsxs(Table.Row, { id: "generalSettingsRow", styles: { minHeight: "50px" }, children: [_jsx(Table.Cell, { style: { width: "12%", justifyContent: "startend" }, children: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsx(Text, { content: t("Permissions:GeneralSetting") }) }) }), _jsx(Table.Cell, { style: { width: "12%", justifyContent: "center" } }), " ", appConfigPerms.map((perms, i) => (_jsx(Table.Cell, { style: { width: "12%", justifyContent: "center" }, children: _jsx(Flex, { hAlign: "center", vAlign: "center", children: _jsx(Checkbox, { checked: logic.state.defaultRight[perms], onChange: () => logic.handleDefaultCheckboxChange(perms), disabled: logic.disableCheckbox }) }) }, "default-perm-" + i))), !isOnMobile && !process.env.REACT_APP_ISPLATINUM && _jsx(Table.Cell, { style: { width: "12%", justifyContent: "center" } })] }) }));
    };
    const renderHeaderTable = () => {
        return (_jsxs(Table.Row, { header: true, id: "headerTable", styles: { backgroundColor: themeClass === ThemeClass.Default ? "#F5F5F5 !important" : "transparent", minHeight: "50px" }, children: [_jsx(Table.Cell, { style: { width: "12%", textAlign: "center", justifyContent: "startend", backgroundColor: "transparent" }, children: _jsx(Flex, { gap: "gap.small", hAlign: "center", vAlign: "center", children: _jsx(Text, { content: t("Permissions:TableName"), weight: "semibold" }) }) }), _jsx(Table.Cell, { style: { width: "12%", textAlign: "center", justifyContent: "start", marginLeft: "14px" }, children: _jsx(Flex, { gap: "gap.small", hAlign: "center", vAlign: "center", children: _jsx(Text, { content: t("Permissions:TableRole"), weight: "semibold" }) }) }), appConfigPerms.map((perms, i) => (_jsx(Table.Cell, { style: { width: "12%", textAlign: "center", justifyContent: "center" }, children: _jsxs(Flex, { gap: "gap.small", hAlign: "center", vAlign: "center", children: [_jsx(Text, { content: t(logic.from + ":Permissions:Table" + perms), weight: "semibold" }), !isOnMobile && (_jsx(Tooltip, { content: t(logic.from + ":Permissions:" + perms + "Info"), children: _jsx(InfoIcon, { outline: true }) }))] }) }, "text-perm-" + i))), !isOnMobile && !process.env.REACT_APP_ISPLATINUM && (_jsx(Table.Cell, { style: { width: "12%", textAlign: "center", justifyContent: "center", marginRight: "0px" }, children: _jsx(Flex, { gap: "gap.small", hAlign: "center", vAlign: "center", children: _jsx(Text, { content: t("Permissions:Subscription"), weight: "semibold" }) }) }))] }));
    };
    const renderBodyTableRow = () => {
        return logic.displayedUsers?.users.map((user, index) => (_jsxs(Table.Row, { id: `userRow-${index}`, styles: { minHeight: "50px" }, children: [_jsx(Table.Cell, { style: { width: "12%", justifyContent: "startend" }, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [isOnMobile && (_jsx(Tooltip, { content: user.name, children: _jsx(Avatar, { name: user.name
                                        .split(" ")
                                        .map((word) => word[0].toUpperCase())
                                        .join("") }) })), !isOnMobile && _jsx(Text, { content: user.name })] }) }), _jsx(Table.Cell, { style: { width: "12%", justifyContent: "start" }, children: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: logic.userPerm.role === Role.creator && user.role !== Role.creator && user.isPlatinum ? (_jsx(Dropdown, { fluid: true, inverted: true, value: logic.handleDisplayRole(user.role), items: logic.roleItems.map((role) => ({
                                key: role.key,
                                header: role.header,
                                onClick: role.key === Role.creator
                                    ? (_, value) => {
                                        setSelectedRole(value);
                                        setUserIndex(index);
                                        logic.toggleManageRoleDialog();
                                    }
                                    : () => { },
                            })), disabled: logic.isCurrentUserNotCreator() }, index)) : (_jsx(Text, { styles: { marginLeft: "14px" }, content: logic.handleDisplayRole(user.role) }, index)) }) }), appConfigPerms.map((perms, i) => (_jsx(Table.Cell, { style: { width: "12%", justifyContent: "center" }, children: _jsx(Flex, { hAlign: "center", vAlign: "center", children: _jsx(Tooltip, { content: logic.showTooltip(user), children: _jsx(Flex, { id: "no-margin", children: _jsx(Checkbox, { onChange: () => logic.changeUserPerm(index, perms), checked: user.perm[perms], disabled: logic.disableCheckbox || user.role === Role.creator || !user.isPlatinum }) }) }, index) }) }, "userpem-" + i))), !isOnMobile && !process.env.REACT_APP_ISPLATINUM && (_jsx(Table.Cell, { style: { width: "12%", justifyContent: "center" }, children: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsx(Tooltip, { content: logic.showLicenseTooltip(user), children: _jsx(Text, { content: logic.handleDisplayLicense(user) }, index) }, index) }) }))] }, "user" + index)));
    };
    return (_jsx(_Fragment, { children: _jsxs(Flex, { column: true, fill: true, styles: {
                display: "flex",
            }, children: [_jsxs(Flex, { id: "no-margin", children: [_jsxs("div", { className: "permissions-top-nav", children: [_jsxs(Flex, { styles: {
                                        padding: isOnMobile ? "15px" : "10px",
                                    }, className: "permissions-top-nav-left", children: [_jsx(Flex, { vAlign: "center", hAlign: "center", gap: "gap.small", children: _jsx(Button, { icon: _jsx(ChevronStartIcon, {}), iconOnly: true, onClick: logic.compareState || logic.userPerm.role === Role.member
                                                    ? logic.returnToApp
                                                    : () => {
                                                        setIsReturnToApp(true), logic.toggleCancelDialog();
                                                    } }) }), _jsx(Header, { as: "h3", content: t("Permissions:Title"), style: { marginLeft: ".5rem" } })] }), _jsxs(Flex, { gap: "gap.small", className: "permissions-top-nav-btns", children: [_jsx(FlexItem, { children: _jsx(Button, { primary: true, size: isOnMobile ? "small" : "medium", content: isOnMobile ? _jsx(SaveIcon, {}) : t("Permissions:SaveButton"), onClick: logic.handleSave, disabled: logic.compareState || logic.isCurrentUserNotCreator() }) }), _jsx(FlexItem, { children: _jsx(Button, { secondary: true, size: isOnMobile ? "small" : "medium", content: isOnMobile ? _jsx(UndoIcon, {}) : t("Permissions:CancelButton"), onClick: () => {
                                                    setIsReturnToApp(false);
                                                    logic.toggleCancelDialog();
                                                }, disabled: logic.compareState || logic.isCurrentUserNotCreator() }) })] })] }), logic.isOpen === Modal.PERMISSIONS_CANCEL_UNSAVE_CHANGE && (_jsx(CancelDialog, { handleCancelConfirmed: logic.handleCancelConfirmed, returnToApp: logic.returnToApp, handlePageChange: logic.handlePageChange, isReturnToApp: isReturnToApp, isPreviousPage: isPreviousPage, isNextPage: isNextPage, currentPage: logic.currentPage, searchOnChange: logic.searchOnChange, isSearch: isSearch }))] }), !logic.isCurrentUserNotCreator() && !logic.isPlatinum && (_jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%", marginBottom: "16px" }, warning: true, content: _jsxs(Flex, { vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { outline: true, style: { marginRight: "10px" } }), _jsx(Text, { styles: {
                                        textDecoration: "none",
                                        cursor: "default",
                                        marginRight: "4px",
                                    }, content: logic.isPremium ? t("Permissions:PremiumRedirect", { appTitle: logic.app }) : t("Permissions:FreeRedirect", { appTitle: logic.app }) }), _jsx(Text, { styles: {
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }, onClick: logic.openPremium, weight: "bold", content: _jsx(_Fragment, { children: t("ClickHere") }) }), _jsx(Text, { styles: {
                                        textDecoration: "none",
                                        cursor: "default",
                                        marginLeft: "4px",
                                    }, content: t("PremiumMessage2") })] }) }) })), logic.bannerState.isSuccessBannerVisible && (_jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%", marginBottom: "16px" }, success: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(PresenceAvailableIcon, { outline: true }), _jsx(Text, { content: t("SaveAlert") })] }) }) })), logic.isCurrentUserNotCreator() && (_jsx(Flex, { children: _jsx(Alert, { styles: { width: "100%", height: "100%", marginBottom: "16px" }, warning: true, content: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [_jsx(ExclamationTriangleIcon, { outline: true }), _jsx(Text, { content: t("NotAllowed") })] }) }) })), _jsx(Segment, { styles: {
                        margin: isOnMobile ? "0px" : "10px",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                    }, children: _jsx(Accordion, { id: "perm-accordion", defaultActiveIndex: logic.openAccordionDefault, panels: [
                            {
                                title: t("GeneralPermissionsSettings"),
                                content: (_jsx(RadioGroup, { vertical: true, items: logic.globalPermissionsItems.map((permission) => ({
                                        key: permission.key,
                                        label: permission.header,
                                        value: permission.key,
                                    })), checkedValue: Object.entries(logic.state.globalPermissions).find((permission) => permission[1])[0], onCheckedValueChange: (_, data) => logic.handlePermissionChange(data.value) }, logic.dropdownKey)),
                            },
                            {
                                title: t("CustomizedPermissionsSettings"),
                                content: renderCustomizeTable(),
                            },
                        ] }) })] }) }));
};
