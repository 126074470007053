import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from "react";
import { timeZones, useLogic } from "./Navbar.logic";
import { AddIcon, Button, CalendarIcon, Checkbox, ChevronEndIcon, ChevronStartIcon, Dropdown, Flex, FlexItem, Input, MenuButton, Ref, SearchIcon, Text, Tooltip } from "@fluentui/react-northstar";
import { AboutDialog, InformationMenu, NotificationsDIalog, UpgradeDialog, useAppConfigurationSelector, useConfigurationSelector, useModalSelector, useMsTeamsSelector, usePermissionsSelector, usePremiumSelector, useTranslate, } from "front";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
import { SecurityMode } from "../../../interfaces/Enums";
import { useNavBarSelector } from "../../../redux/reducers/navbarReducer";
import { Settings24Regular } from "@fluentui/react-icons";
import Frame from "react-frame-component";
import { Modal } from "../../../interfaces/modalInterface";
import { SettingsDialog } from "../../dialogs/Settings/Settings.dialog";
import { ResourcesDialog } from "../../dialogs/Resources/Resource.dialog";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
import { translations } from "../../../translations";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../../assets/scss/navbar.scss";
export const CalendarNavbar = (props) => {
    const t = useTranslate(translations);
    const logic = useLogic(props);
    const navigation = useNavigate();
    const { appInsightInstance } = useSelector((s) => s.infosApp);
    const { allowSync } = useOptionsSelector("allowSync");
    const { isOpen } = useModalSelector("isOpen");
    const { data: config } = useConfigurationSelector("data");
    const { isResourceMode, from } = useAppConfigurationSelector("isResourceMode", "from");
    const { isOnMobile, userMail } = useMsTeamsSelector("isOnMobile", "userMail");
    const { isPremium, isPlatinum } = usePremiumSelector("isPremium", "isPlatinum");
    const { searchTag, calendarTimeZone } = useNavBarSelector("searchTag", "calendarTimeZone");
    const { calendar } = useCalendarCache();
    const { userPerm } = usePermissionsSelector("userPerm");
    const styles = {
        color: (index) => ({
            width: "1rem",
            height: "1rem",
            borderRadius: "2px",
            padding: "0.125rem",
            background: props.displayedCalendars[index]?.color ?? "#6264a7",
        }),
        swatch: {
            background: "#fff",
            borderRadius: "2px",
            marginTop: "0.175rem",
            border: "0.0625rem solid rgb(91, 95, 199)",
            display: "inline-block",
            cursor: "pointer",
        },
    };
    document.onclick = (e) => {
        const calendarDropdownOpen = document.getElementById("dropdown-shared-calendars");
        if (e.target.id === "dropdown-shared-calendars") {
            logic.setIsDropdownSharedCalendarsOpen(true);
        }
        else if (logic.isDropdownSharedCalendarsOpen) {
            if (!e.target.id.includes("dropdown-list-shared-calendars") && calendarDropdownOpen?.contains(e.target) === false) {
                logic.setIsDropdownSharedCalendarsOpen(false);
            }
        }
        const tagsDropdownOpen = document.getElementById("dropdown-tags");
        if (e.target.id === "dropdown-tags") {
            logic.setIsDropdownTagsOpen(true);
        }
        else if (logic.isDropdownTagsOpen) {
            if (!e.target.id.includes("dropdown-list-tags") && tagsDropdownOpen?.contains(e.target) === false) {
                logic.setIsDropdownTagsOpen(false);
            }
        }
    };
    const calendarNavigation = useMemo(() => (_jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [from == "SharedCalendar" &&
                _jsx(Button, { "data-testid": "navbar-today-btn", primary: true, styles: { minWidth: "auto" }, content: t("Today"), disabled: props.isLoading, onClick: logic.showTodayEvents }), _jsxs(Flex, { children: [_jsx(Button, { "data-testid": "navbar-previous-btn", icon: _jsx(ChevronStartIcon, {}), iconOnly: true, onClick: logic.showPreviousEvents, styles: { marginRight: "5px" }, disabled: props.isLoading }), _jsx(Button, { "data-testid": "navbar-next-btn", icon: _jsx(ChevronEndIcon, {}), iconOnly: true, onClick: logic.showNextEvents, disabled: props.isLoading })] }), from != "SharedCalendar" &&
                _jsx(Button, { "data-testid": "navbar-today-btn", primary: true, styles: { minWidth: "auto" }, content: t("Today"), disabled: props.isLoading, onClick: logic.showTodayEvents }), _jsx(FlexItem, { push: isOnMobile, children: _jsx(Text, { styles: { whiteSpace: "nowrap" }, weight: "bold", content: props.currentCalendarTitle }) })] })), [props.isLoading, props.currentCalendarTitle, logic.showPreviousEvents, logic.showNextEvents, logic.isTodayButtonDisabled, logic.showTodayEvents, t, isOnMobile]);
    const securityModeAlert = useMemo(() => calendar?.securityMode === SecurityMode.OnlyMeCanEdit && calendar.createdBy?.toLowerCase() !== userMail.toLowerCase() ? (_jsx(Text, { weight: "semibold", content: (calendar?.createdBy ?? "") + t("MessageOnlyMePrevent") })) : null, [calendar, userMail, t]);
    const calendarsDropdownFilter = useMemo(() => (_jsx(Dropdown, { "data-testid": "navbar-sync-calendar-dpdn", id: "dropdown-shared-calendars", value: props.displayedCalendars && props.displayedCalendars.length > 0
            ? `${t("CalendarsTitle")} ${props.displayedCalendars.filter((c) => c.display).length}/${props.displayedCalendars.length}`
            : `${t("CalendarsTitle")} 0/0`, items: props.displayedCalendars.map((calendar, index) => ({
            id: "dropdown-list-shared-calendars",
            key: index,
            content: (_jsxs(Flex, { vAlign: "center", children: [_jsx(FlexItem, { children: _jsx("div", { id: "sync-color-picker-button", style: styles.swatch, children: _jsx("div", { id: "sync-color-picker-buttoncolor", style: styles.color(index) }) }) }), _jsx(FlexItem, { styles: { paddingLeft: "5px" }, children: _jsx(Text, { content: calendar.name }) }), _jsx(FlexItem, { push: true, children: _jsx(Checkbox, { checked: props.displayedCalendars.find((c) => c.id === calendar.id).display, onChange: () => logic.handleSelectedCalendars(calendar) }) })] }, index)),
        })), disabled: !isPlatinum || props.isLoading, inverted: true, fluid: true, styles: {
            maxHeight: "34px !important",
            value: {
                fontWeight: "bold !important",
            },
            dropdownItem: {
                maxHeight: "30px",
                overflowY: "auto",
            },
        }, open: logic.isDropdownSharedCalendarsOpen, onOpenChange: () => logic.setIsDropdownSharedCalendarsOpen(true) })), [isPlatinum, t, props.displayedCalendars, logic.isDropdownSharedCalendarsOpen, logic.setIsDropdownSharedCalendarsOpen, logic.onDropdownSharedCalendarsToggle, logic.handleSelectedCalendars, props.isLoading]);
    const inputItems = useMemo(() => calendar?.tags?.map((tag, index) => ({
        id: tag,
        key: index,
        content: (_jsxs(Flex, { vAlign: "center", styles: { height: isOnMobile ? "30px" : "auto", }, children: [_jsx(FlexItem, { styles: { paddingLeft: "5px" }, children: _jsx(Text, { content: tag }) }), _jsx(FlexItem, { push: true, styles: { paddingLeft: "12px" }, children: _jsx(Checkbox, { checked: searchTag.split(";;;").includes(tag), onChange: () => logic.onFilterEventsByTag(tag) }) })] }, index)),
    })), [logic.onFilterEventsByTag, searchTag, calendar, isOnMobile]);
    const itemFilter = useMemo(() => inputItems?.filter((i) => i.id.toLowerCase().includes(logic.searchTagsQuery.toLowerCase())), [logic.searchTagsQuery, inputItems]);
    const tagsDropdownFilter = useMemo(() => {
        const searchItem = [{
                id: "dropdown-search-bar",
                key: "search-bar",
                content: (_jsxs(Flex, { column: true, children: [_jsx(Input, { id: 'tag-input', "data-testid": "", placeholder: t("Tag:Search"), value: logic.searchTagsQuery, onChange: (e, { value }) => logic.setSearchTagsQuery(value), onClick: (e) => { e.stopPropagation(); document.getElementById("tag-input").focus(); }, icon: _jsx(SearchIcon, {}), fluid: true, styles: { width: "250px" }, clearable: true, error: itemFilter?.length === 0, errorIndicator: itemFilter?.length === 0 }), itemFilter?.length === 0 && _jsx(Text, { error: true, content: t("Tag:NoResult") })] })),
            }];
        if (isPremium) {
            return (_jsx(Dropdown, { id: "dropdown-tags", "data-testid": "navbar-tag-dpdn", value: calendar?.tags && calendar?.tags.length > 0
                    ? `${t("Tags")} ${searchTag !== "" ? searchTag?.split(";;;")?.length : "0"}/${calendar?.tags.length}`
                    : `${t("Tags")} 0/0`, items: calendar?.tags?.length > 0 ? [...searchItem, ...itemFilter ?? []] : [], disabled: !isPremium || props.isLoading, inverted: true, fluid: true, styles: {
                    maxHeight: "34px !important",
                    value: {
                        fontWeight: "bold !important",
                    },
                    dropdownItem: {
                        maxHeight: isOnMobile ? "45px" : "30px",
                        overflowY: "auto",
                    },
                    "& ul": {
                        maxHeight: "300px !important"
                    }
                }, open: logic.isDropdownTagsOpen, onOpenChange: () => logic.setIsDropdownTagsOpen(true), noResultsMessage: calendar?.tags?.length > 0
                    ? t("NoTagFound")
                    : _jsx("div", { onClick: () => navigation("/tags/" + calendar?.id), children: t("noTag") }) }));
        }
        return (_jsx(Tooltip, { content: t("AlertTagsAndMore"), children: _jsx(Dropdown, { id: "dropdown-tags", "data-testid": "navbar-tag-dpdn", value: calendar?.tags && calendar?.tags.length > 0
                    ? `${t("Tags")} ${searchTag !== "" ? searchTag?.split(";;;")?.length : "0"}/${calendar?.tags.length}`
                    : `${t("Tags")} 0/0`, items: calendar?.tags?.length > 0 ? [...searchItem, ...itemFilter ?? []] : [], disabled: !isPremium || props.isLoading, inverted: true, fluid: true, styles: {
                    maxHeight: "34px !important",
                    value: {
                        fontWeight: "bold !important",
                    },
                    dropdownItem: {
                        maxHeight: isOnMobile ? "45px" : "30px",
                        overflowY: "auto",
                    },
                    "& ul": {
                        maxHeight: "300px !important"
                    }
                }, open: logic.isDropdownTagsOpen, onOpenChange: () => logic.setIsDropdownTagsOpen(true), noResultsMessage: calendar?.tags?.length > 0
                    ? t("NoTagFound")
                    : _jsx("div", { onClick: () => navigation("/tags/" + calendar?.id), children: t("noTag") }) }) }));
    }, [props.isLoading, isPremium, t, calendar, searchTag, logic.isDropdownTagsOpen, logic.searchTagsQuery, logic.setIsDropdownTagsOpen, logic.onDropdownTagsToggle, logic.onFilterEventsByTag]);
    const timezoneDropdownFilter = useMemo(() => (_jsx(Dropdown, { "data-testid": "navbar-timezone-dpdn", id: "dropdown-tz", search: true, fluid: true, styles: { maxHeight: "34px" }, inverted: true, items: timeZones, placeholder: calendarTimeZone, searchQuery: logic.searchQuery, onSearchQueryChange: logic.changeSearchQuery, onChange: logic.onChangeCalendarTimeZone, disabled: props.isLoading })), [timeZones, calendarTimeZone, logic.onChangeCalendarTimeZone, props.isLoading]);
    const newEventButton = useMemo(() => {
        return props.userCanEditCalendar ? (_jsx(Button, { "data-testid": "navbar-new-event-btn", styles: { minWidth: "auto" }, primary: true, disabled: !userPerm?.perm?.add || props.isLoading, content: t("AddEvent"), icon: _jsx(AddIcon, {}), onClick: props.onCreateNewEvent })) : null;
    }, [props.userCanEditCalendar, props.onCreateNewEvent, t]);
    const viewModeButton = useMemo(() => {
        if (from == "SharedCalendar") {
            return (_jsx(Dropdown, { "data-testid": "navbar-view-dpdn", id: "dropdown-view", fluid: true, styles: { maxHeight: "34px" }, inverted: true, value: logic.getTranslatedCurrentDisplayMode(), items: logic.displayModesDropdownItems, onChange: logic.onChangeCalendarView, disabled: props.isLoading }));
        }
        return (_jsx(MenuButton, { styles: { width: isOnMobile ? "100%" : "auto" }, trigger: _jsx(Button, { "data-testid": "navbar-view-btn", fluid: isOnMobile, primary: true, icon: _jsx(CalendarIcon, {}), content: logic.getTranslatedCurrentDisplayMode(), disabled: props.isLoading, styles: { pointerEvents: props.isLoading ? "none" : "auto" } }), menu: {
                styles: isOnMobile ? { minWidth: "calc(100vw - 20px)" } : {},
                fluid: isOnMobile,
                items: logic.displayModesDropdownItems ?? [],
            }, onMenuItemClick: logic.onChangeDisplayMode }));
    }, [isOnMobile, from, logic.displayModesDropdownItems, logic.getTranslatedCurrentDisplayMode, logic.onChangeDisplayMode, props.isLoading]);
    const moreOptionsButton = useMemo(() => (_jsx(MenuButton, { trigger: _jsx(Button, { "data-testid": "navbar-more-options-btn", secondary: true, iconOnly: true, icon: _jsx(Settings24Regular, {}), disabled: props.isLoading, styles: { pointerEvents: props.isLoading ? "none" : "auto" } }), menu: logic.moreOptionsDropdownItems, onMenuItemClick: logic.onClickMoreOptionsAction })), [props.isLoading, logic.moreOptionsDropdownItems, logic.onClickMoreOptionsAction]);
    const helpButton = () => _jsx(InformationMenu, { isDisabled: props.isLoading });
    /**
     * Render iframe to print calendar
     */
    const renderPrintIframe = () => {
        if (!logic.state.imageToPrint)
            return;
        return !isOnMobile ? (_jsxs(Frame, { id: "printContentIframe", name: "printContentIframe", style: { display: "none" }, children: [_jsxs("div", { style: { marginBottom: 10 }, children: [calendar?.name, " - ", props.currentCalendarTitle] }), _jsx("img", { style: { width: "100%", height: "100%" }, src: logic.state.imageToPrint, alt: "imagePrint" })] })) : null;
    };
    const renderSettingsDialog = () => {
        if (isOpen != Modal.Settings)
            return null;
        return _jsx(SettingsDialog, { refreshPublicHolidays: props.refreshPublicHolidays, onHide: logic.closeSettings });
    };
    const renderRoomsDialog = () => {
        if (isOpen != Modal.Rooms)
            return null;
        return _jsx(ResourcesDialog, {});
    };
    /**
     * Render dialog to get app details
     */
    const renderGetIdDialog = () => {
        if (isOpen != Modal.SHOWGETIDDIALOG)
            return;
        return _jsx(AboutDialog, {});
    };
    /**
     * Render dialog to get app details
     */
    const renderNotificationDialog = () => {
        if (isOpen != Modal.NOTIFICATIONS)
            return;
        return _jsx(NotificationsDIalog, { firstCheckBox: t("AddUpdateNotif"), firstCheckBoxDesc: t("AddUpdateNotifDesc"), secondCheckBox: t("DeleteNotif"), secondCheckBoxDesc: t("DeleteNotifDesc") });
    };
    const renderUpgradeDialog = () => {
        if (isOpen != Modal.UPGRADE)
            return null;
        return _jsx(UpgradeDialog, { appId: config.adminCenterAppId, appInsight: appInsightInstance });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Ref, { innerRef: logic.onRenderNavbarHtmlElement, children: _jsx(Flex, { gap: "gap.small", padding: "padding.medium", vAlign: "center", children: _jsxs(Flex, { fill: true, gap: "gap.small", vAlign: "center", column: isOnMobile, children: [calendarNavigation, securityModeAlert, _jsx(FlexItem, { push: !isOnMobile, children: _jsxs(Flex, { gap: "gap.small", vAlign: "center", children: [props.displayedCalendars.length > 0 && allowSync && calendarsDropdownFilter, tagsDropdownFilter, from != "SharedCalendar" && timezoneDropdownFilter] }) }), _jsxs(Flex, { hAlign: "end", gap: "gap.small", children: [newEventButton, viewModeButton, moreOptionsButton, helpButton()] })] }) }) }), renderGetIdDialog(), renderNotificationDialog(), !!logic.state.imageToPrint && renderPrintIframe(), renderSettingsDialog(), renderUpgradeDialog(), renderRoomsDialog()] }));
};
